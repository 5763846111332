import { useContext, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import ProgressLoader from "../utils/ProgressLoader";
import moment from "moment";
import { red } from "@mui/material/colors";
import { Delete, MoreVert } from "@mui/icons-material";
import { behaviorIconFactory } from "../icons/BehaviorIcons";
import ContentEmbedCard from "./ContentEmbedCard";
import { UserContext } from "../../contexts/UserContext";

const i18n = {
  deleteButton: "Delete",
};

const QueueSharedCard = ({ bot, isDeleting, onDelete, post }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { content, definition, meta, publish_at, action_text, published_url, publised_at } =
    post;

  const { blockchain } = meta;
  const isMenuOpen = Boolean(anchorEl);

  const { hasUser } = useContext(UserContext);
  const belongsToUser = () => {
    return hasUser() && bot.is_owner;
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderContent = () => {
    return (
      <CardContent>
        {content && <ContentEmbedCard {...content} />}
        <Typography sx={{ marginTop: content ? 2 : 0 }}>
          {action_text}
        </Typography>
      </CardContent>
    );
  };

  const renderMenu = () => {
    if (!belongsToUser()) {
      return null;
    }

    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem
          sx={{ color: red[500] }}
          onClick={() => {
            handleMenuClose();
            onDelete();
          }}
        >
          <ListItemIcon>
            <Delete sx={{ color: red[500] }} />
          </ListItemIcon>
          {i18n.deleteButton}
        </MenuItem>
      </Menu>
    );
  };

  return (
    <Card sx={{ marginRight: 1, marginBottom: 1, width: 320 }} raised>
      <CardHeader
        action={
          belongsToUser() ? (
            <IconButton aria-haspopup="true" onClick={handleMenuOpen}>
              {isDeleting ? <ProgressLoader /> : <MoreVert />}
            </IconButton>
          ) : null
        }
        avatar={behaviorIconFactory(definition.type, blockchain)}
        title={definition.title}
        subheader={
          <Link href={published_url} rel="noreferrer" target="_blank">
            {`Shared on ${moment(publised_at).format("MMM Do, h:mm a")}`}
          </Link>
        }
      />
      {renderContent()}
      {renderMenu()}
    </Card>
  );
};

export default QueueSharedCard;
